<template>
  <v-card :loading="isLoading" outlined>
    <v-toolbar flat dense color="grey lighten-3">
      <v-toolbar-title>
        <v-layout class="pa-0 ma-0" row>
          <h1 class="subtitle-1">Sale By Rep</h1>
        </v-layout>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <DateTimePicker
        defaultRange="since7DaysAgo"
        :range="true"
        v-model="dateTime"
        @submit="getAll()"
      />
    </v-toolbar>
    <v-card-text class="list-500 pa-0" v-if="invoices.length > 0">
      <v-list v-if="!isLoading">
        <template v-for="(invoice, index) in invoices">
          <div :key="index">
            <v-list-item @click="viewReport(invoice)">
              <v-list-item-avatar>
                <v-img v-if="invoice.imageUrl" :src="invoice.imageUrl"></v-img>
                <v-img v-else src="@/assets/images/user.jpeg"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{
                  invoice.name
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-title>
                  {{ invoice.totalSale | currency }}
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index + 1 < invoices.length"></v-divider>
          </div>
        </template>
      </v-list>
    </v-card-text>
    <v-card-text v-else>
      <lottie
        :height="200"
        :width="200"
        :options="defaultOptions"
        v-on:animCreated="handleAnimation"
      />
      <div class="text-center">No Invoices</div>
    </v-card-text>
    <v-toolbar flat dense color="grey lighten-3">
      <v-toolbar-title>
        <v-layout class="pa-0 ma-0" row>
          <h1 class="subtitle-1">Total</h1>
        </v-layout>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="font-weight-bold">{{ total | currency }}</span>
    </v-toolbar>
  </v-card>
</template>

<script>
import invoiceService from "@/modules/Invoice/service";
import Lottie from "@/components/lottie.vue";
import * as animationData from "@/assets/lottie/13625-list.json";
const DateTimePicker = () => import("@/components/DateTimePicker");

export default {
  data() {
    return {
      isLoading: true,
      invoices: [],
      dateTime: {
        start: this.$moment().startOf("day").toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      defaultOptions: { animationData: animationData.default },
    };
  },
  components: {
    DateTimePicker,
    Lottie,
  },
  mounted() {
    this.getAll();
  },
  computed: {
    total() {
      let total = 0;
      this.invoices.map((row) => {
        total += row.totalSale;
      });
      return total;
    },
  },
  methods: {
    viewReport(invoice) {
      let routeData = null;
      routeData = this.$router.resolve({
        path: `/global/salesman/${invoice.id}/view?start=${this.dateTime.start}&end=${this.dateTime.end}`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    handleAnimation(anim) {
      this.anim = anim;
    },
    getAll() {
      let where = {
        start: this.dateTime.start,
        end: this.dateTime.end,
      };
      return invoiceService.invoiceBySalesRep(where).then((result) => {
        // console.log("result invoice", result);
        this.invoices = result.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
