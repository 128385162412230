<template>
  <v-layout row wrap>
    <v-flex>
      <v-card
        color="yellow lighten-1"
        dark
        hover
        outlined
        flat
        @click="$router.push({ path: '/customer/list' })"
      >
        <v-card-text style="height: 128px;">
          <v-layout
            class="black--text"
            align-center="align-center"
            justify-center="justify-center"
            column="column"
            fill-height="fill-height"
          >
            <v-progress-circular
              v-if="isLoading"
              size="50"
              width="5"
              color="black"
              indeterminate="indeterminate"
            ></v-progress-circular>
            <animated-number
              v-else
              class="display-3 font-weight-light"
              :value="currentMonthToDate"
              :formatValue="formatToNumber"
              :duration="1000"
            >
            </animated-number>
            <div class="text-center body-1">Customer Count</div>
            <small>This Month To Date</small>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex>
      <v-card
        color="yellow lighten-2"
        dark
        hover
        outlined
        flat
        @click="$router.push({ path: '/customer/list' })"
      >
        <v-card-text style="height: 128px;">
          <v-layout
            class="black--text"
            align-center="align-center"
            justify-center="justify-center"
            column="column"
            fill-height="fill-height"
          >
            <v-progress-circular
              v-if="isLoading"
              size="50"
              width="5"
              color="black"
              indeterminate="indeterminate"
            ></v-progress-circular>
            <animated-number
              v-else
              class="display-3 font-weight-light"
              :value="lastMonthToDate"
              :formatValue="formatToNumber"
              :duration="1000"
            >
            </animated-number>
            <div class="text-center body-1">New Customer</div>
            <small>Last Month To Date</small>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import customerService from "../../service";

export default {
  data() {
    return {
      currentMonthToDate: 0,
      lastMonthToDate: 0,
      isLoading: true
    };
  },
  components: {
    AnimatedNumber
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatToNumber(value) {
      return `${value.toFixed(0)}`;
    },
    async loadData() {
      let where = {};

      where.limit = 1;
      where.start = this.$moment()
        .startOf("month")
        .toISOString();
      where.end = this.$moment()
        .endOf("day")
        .toISOString();

      const thisMonth = await customerService.getAllCount(where);

      where.start = this.$moment()
        .subtract(1, "month")
        .startOf("month")
        .toISOString();
      where.end = this.$moment()
        .subtract(1, "month")
        .endOf("day")
        .toISOString();

      const lastMonth = await customerService.getAllCount(where);

      this.currentMonthToDate = thisMonth.count;
      this.lastMonthToDate = lastMonth.count;

      this.isLoading = false;
    }
  }
};
</script>

<style scoped></style>
