<template>
  <v-card :loading="isLoading" outlined>
    <v-toolbar flat dense color="grey lighten-3">
      <v-toolbar-title>
        <v-layout class="pa-0 ma-0" row>
          <h1 class="subtitle-1">Payments</h1>
        </v-layout>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <DateTimePicker
        defaultRange="today"
        :range="true"
        v-model="dateTime"
        @submit="getAll()"
      />
    </v-toolbar>
    <v-card-text class="list-500 pa-0" v-if="payments.length > 0">
      <v-list v-if="!isLoading">
        <template v-for="(payment, index) in payments">
          <div :key="index">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">{{
                  payment.name
                }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-title>
                  {{ payment.totalPayments | currency }}
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index + 1 < payments.length"></v-divider>
          </div>
        </template>
      </v-list>
    </v-card-text>
    <v-card-text v-else>
      <div class="text-center">
        <div class="title">Non Payment Collected</div>
      </div>
    </v-card-text>
    <v-toolbar flat dense color="grey lighten-3">
      <v-toolbar-title>
        <v-layout class="pa-0 ma-0" row>
          <h1 class="subtitle-1">Total</h1>
        </v-layout>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="font-weight-bold">{{ total | currency }}</span>
    </v-toolbar>
  </v-card>
</template>

<script>
import paymentService from "@/modules/Customer/Payment/service";
const DateTimePicker = () => import("@/components/DateTimePicker");

export default {
  data() {
    return {
      isLoading: true,
      payments: [],
      dateTime: {
        start: this.$moment().startOf("day").toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
    };
  },
  components: {
    DateTimePicker,
  },
  mounted() {
    this.getAll();
  },
  computed: {
    total() {
      let total = 0;
      this.payments.map((row) => {
        total += row.totalPayments;
      });
      return total;
    },
  },
  methods: {
    getAll() {
      let where = {
        start: this.dateTime.start,
        end: this.dateTime.end,
      };
      return paymentService.groupByCustomer(where).then((result) => {
        // console.log('result', result)
        this.payments = result.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
