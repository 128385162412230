<template>
  <v-card color="yellow darken-1" dark hover outlined flat @click="$router.push({ path: '/customer/list' })">
    <v-card-text style="height: 128px">
      <v-layout class="white--text" align-center="align-center" justify-center="justify-center" column="column"
        fill-height="fill-height">
        <v-progress-circular v-if="isLoading" size="50" width="5" color="white"
          indeterminate="indeterminate"></v-progress-circular>

        <!-- <v-skeleton-loader v-bind="attrs" type="date-picker"></v-skeleton-loader> -->

        <animated-number v-else class="display-3 font-weight-light" :value="amount" :formatValue="formatToNumber"
          :duration="1000">
        </animated-number>
        <div class="text-center body-1">Customer Count</div>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import customerService from "../../service";
import AnimatedNumber from "animated-number-vue";

export default {
  data() {
    return {
      isLoading: true,
      amount: 0,
      // attrs: {
      //   class: 'mb-6',
      //   boilerplate: true,
      //   elevation: 2,
      // },
    };
  },
  components: {
    AnimatedNumber,
  },
  created() {
    this.loadData();
  },
  methods: {
    formatToNumber(value) {
      return `${value.toFixed(0)}`;
    },
    loadData() {
      customerService.getAll({ limit: 1 }).then((response) => {
        // // console.log('resp', response)
        this.amount = response.count;

        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
