<template>
  <v-card
    color="yellow lighten-3"
    dark
    hover
    outlined
    @click="$router.push({ path: '/customer/list' })"
  >
    <v-card-text style="height: 128px;">
      <v-layout
        class="black--text"
        align-center="align-center"
        justify-center="justify-center"
        column="column"
        fill-height="fill-height"
      >
        <v-progress-circular
          v-if="isLoading"
          size="50"
          width="5"
          color="black"
          indeterminate="indeterminate"
        ></v-progress-circular>
        <v-layout row v-else justify-center>
          <v-flex>
            <animated-number
              class="display-2 font-weight-light"
              :value="currentMonthToDate"
              :formatValue="formatToNumber"
              :duration="1000"
            >
            </animated-number>
          </v-flex>
          <v-flex class="display-2 font-weight-light"> /</v-flex>
          <v-flex>
            <animated-number
              class="display-2 font-weight-light"
              :value="lastMonthToDate"
              :formatValue="formatToNumber"
              :duration="1000"
            >
            </animated-number>
          </v-flex>
        </v-layout>

        <div class="text-center body-1">Sale Count</div>
        <small>This Month / Last Month To Date</small>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import invoiceService from "@/modules/Invoice/service";

export default {
  data() {
    return {
      currentMonthToDate: 0,
      lastMonthToDate: 0,
      isLoading: true
    };
  },
  components: {
    AnimatedNumber
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatToNumber(value) {
      return `${value.toFixed(0)}`;
    },
    async loadData() {
      let where = {};

      where.limit = 1;
      where.start = this.$moment()
        .startOf("month")
        .toISOString();
      where.end = this.$moment()
        .endOf("day")
        .toISOString();

      const thisMonth = await invoiceService.getAll(where);

      where.start = this.$moment()
        .subtract(1, "month")
        .startOf("month")
        .toISOString();
      where.end = this.$moment()
        .subtract(1, "month")
        .endOf("day")
        .toISOString();

      const lastMonth = await invoiceService.getAll(where);

      this.currentMonthToDate = thisMonth.count;
      this.lastMonthToDate = lastMonth.count;

      this.isLoading = false;
    }
  }
};
</script>

<style scoped></style>
