<template>
  <v-card :loading="isLoading" outlined>
    <v-toolbar class="elevation-0" dense color="grey lighten-3">
      <v-toolbar-title>
        <v-layout class="pa-0 ma-0" row>
          <h1 class="subtitle-1">Pending Order</h1>
        </v-layout>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <DateTimePicker defaultRange="since30DaysAgo" :range="true" v-model="dateTime" @submit="getAll()" />
    </v-toolbar>
    <v-card-text class="list-500 pa-0" v-if="orders.length > 0">
      <v-list two-line v-if="!isLoading">
        <template v-for="(order, index) in orders">
          <div :key="index">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-if="order.Customer">{{ order.Customer.name }}
                  <v-chip color="green lighten-3" small v-if="order.isRemoteOrder"
                    text-color="green darken-4">Online</v-chip></v-list-item-title>
                <v-list-item-title v-else>
                  <span v-if="order.metadata && order.metadata.name">{{ order.metadata.name }}</span> <v-chip
                    color="green lighten-3" small v-if="order.isRemoteOrder" text-color="green darken-4">Online</v-chip>
                  <div class="caption" v-if="order.metadata && order.metadata.phoneNumber">{{ order.metadata.phoneNumber
                    }}</div>
                </v-list-item-title>
                <v-list-item-subtitle class="text--primary">
                  Order # {{ order.localId }} for: {{ order.total | currency }}
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle> Order # {{order.localId}} for: {{order.total | currency}}
                </v-list-item-subtitle> -->
              </v-list-item-content>
              <v-list-item-action-text v-if="!order.Customer">
                <v-chip color="blue lighten-3" small v-if="order.isRemoteOrder" text-color="blue darken-4">Unknow
                  Customer</v-chip>
              </v-list-item-action-text>
              <v-list-item-action>
                <v-list-item-action-text>
                  {{ order.date | moment("from", "now") }}
                </v-list-item-action-text>
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item @click="viewOrder(order)">
                      <v-list-item-title>View Order</v-list-item-title>
                    </v-list-item>
                    <OrderStatus v-if="!order.isRemoteOrder" :order="order" @submit="getAll()" />
                    <v-divider></v-divider>
                    <v-list-item @click="deleteOrder(order)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index + 1 < orders.length"></v-divider>
          </div>
        </template>
      </v-list>
    </v-card-text>
    <v-card-text v-else>
      <lottie 
      :height="200" 
      :width="200" 
      :options="defaultOptions" 
      v-on:animCreated="handleAnimation"/>
      <div class="text-center">No Order</div>
    </v-card-text>
  </v-card>
</template>

<script>
import orderService from "@/modules/Order/InHouse/service";
import OrderStatus from "@/modules/Order/InHouse/components/OrderStatus";
import Lottie from "@/components/lottie.vue";
import * as animationData from "@/assets/lottie/empty_box.json";
const DateTimePicker = () => import("@/components/DateTimePicker");
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  data() {
    return {
      payments: [],
      dateTime: {
        start: this.$moment().startOf("day").toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      orders: [],
      isLoading: true,
      updateInfo: false,
      // defaultOptions: { animationData: animationData.default },
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: animationData.default
      }
    };
  },
  components: {
    OrderStatus,
    Lottie,
    DateTimePicker,
  },
  mounted() {
    this.getAll();
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    deleteOrder(order) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          orderService.delete(order.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Order has been deleted.", "success");
              this.getAll();
            }
          });
        }
      });
    },
    // http://localhost:8082/global/order/wqM/view
    viewOrder(order) {
      const hashId = hashids.encode(order.id);
      console.log('hashId', hashId)
      const routeData = this.$router.resolve({
        path: `/global/order/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    getAll() {
      let where = {
        start: this.dateTime.start,
        end: this.dateTime.end,
        pending: false,
      };
      return orderService.getAll(where).then((result) => {
        // console.log('result orfder', result)
        this.orders = result.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
