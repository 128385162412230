<template>
  <v-container fluid grid-list-lg>
    <v-row>
      <v-col cols="12" sm="12" md="3" v-if="checkRightStatus(60)">
        <CustomerCount />
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="checkRightStatus(60)">
        <SignUpCount />
      </v-col>
      <v-col cols="12" sm="12" md="3" v-if="checkRightStatus(60)">
        <InvoiceCount />
      </v-col>
      <v-col cols="12" sm="12" v-if="checkRightStatus(46)">
        <SaleCount />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row class="pt-0">
          <v-col cols="12" class="pt-0" v-if="checkRightStatus(56)">
            <PendingOrder />
          </v-col>
          <v-col cols="12" v-if="checkRightStatus(59)">
            <ReOrderList />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row class="pt-0">
          <v-col cols="12" class="pt-0"  v-if="checkRightStatus(57)">
            <InvoiceBySalesRep />
          </v-col>
          <v-col cols="12"  v-if="checkRightStatus(58)">
            <TodayPayment />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CustomerCount from "@/modules/Customer/components/Tile/Count";
import SignUpCount from "@/modules/Customer/components/Tile/SignUpCount";
import InvoiceCount from "@/modules/Customer/components/Tile/InvoiceCount";
import PendingOrder from "@/modules/Home/components/PendingOrder";
import ReOrderList from "@/modules/Home/components/ReOrderList.vue";
import TodayPayment from "@/modules/Customer/Payment/Table/TodayPayment";
import InvoiceBySalesRep from "@/modules/Invoice/components/InvoiceBySalesRep";
import SaleCount from "@/modules/Home/components/Charts/SaleCount";
import RegisterStoreModule from "@/mixins/RegisterStoreModule";

import { mapGetters } from "vuex";

export default {
  name: "home",
  mixins: [RegisterStoreModule],
  components: {
    CustomerCount,
    PendingOrder,
    SaleCount,
    TodayPayment,
    InvoiceCount,
    SignUpCount,
    InvoiceBySalesRep,
    ReOrderList,
  },
  created() {
    document.title = "smplsale - Home";
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
};
</script>
