<template>
  <v-card :loading="loading" outlined>
    <ListTitle :access="false" title="Sales" :showBackButton="false" />
    <div id="stock-line"></div>
  </v-card>
</template>

<script>
import homeService from "@/modules/Home/service";
import Highcharts from "highcharts";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  mounted() {

    this.loadData();
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    async loadData() {
      this.loading = true;
      const response = await homeService.getSalesByPeriod({ orderBy: 'month' });
      // console.log("response.data[0]", response.data);
      const currentMonth = new Array(12).fill(null);
      const lastMonth = new Array(12).fill(null);
      let diffArray = [];
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      // response.data[0].map((row) => {
      //   saleRow.push(row.total_sales);
      //   yearRow.push(row.date);
      // });
      // let monthCount = 0

      // response.data.currentMonth[0].map((row) => {
      //   currentMonth.push(row.total_sales);
      // });
      // response.data.lastMonth[0].map((row) => {
      //   lastMonth.push(row.total_sales);
      // });

      // Fill in the currentMonth array
      response.data.currentMonth[0].forEach(row => {
        currentMonth[row.date - 1] = row.total_sales;  // Adjusting index for 0-based array
      });

      // Fill in the lastMonth array
      response.data.lastMonth[0].forEach(row => {
        lastMonth[row.date - 1] = row.total_sales;  // Adjusting index for 0-based array
      });

      // currentMonth.map(row => {
      //   // console.log('row', row)
      // })

      // for (let i = 0; i < lastMonth.length; i++) {
      //   diffArray.push(currentMonth[i] - lastMonth[i]);
      // }



      this.loading = false;
      if (response.data && response.data.currentMonth[0]) {
        Highcharts.chart("stock-line", {
          credits: false,
          chart: {
            type: "areaspline",
          },
          title: false,
          xAxis: {
            allowDecimals: false,
            categories: months,
            tickmarkPlacement: "on",
            title: {
              enabled: false,
            },
          },
          yAxis: {
            title: {
              text: "Sales",
            },
            labels: {
              formatter: function () {
                return this.value / 1000 + "k";
              },
            },
          },
          tooltip: {
            shared: true,
            formatter: function () {
              let s = `<strong>${this.x}</strong><br/>`;
              this.points.forEach(point => {
                s += `<span>${point.series.name}</span>: <b>$${Highcharts.numberFormat(point.y, 2, '.', ',')}</b><br/>`;
              });
              return s;
            }
          },
          plotOptions: {
            area: {
              stacking: "normal",
              lineColor: "#666666",
              lineWidth: 1,
              marker: {
                lineWidth: 1,
                lineColor: "#666666",
              },
            }
          },
          series: [
            {
              color: '#E47200',
              name: "Last Year",
              data: lastMonth,
            },
            {
              color: '#FDF98B',
              name: "Current Year",
              data: currentMonth,
            },
          ],
        });
        // Highcharts.chart("stock-line", {
        //   credits: false,
        //   // colors: ['#4a89dc', '#B20C24', '#3bafda'],
        //   chart: {
        //     type: "areaspline",
        //   },
        //   title: false,
        //   // xAxis: {
        //   //   type: 'datetime',
        //   //   dateTimeLabelFormats: {
        //   //     day: '%e of %m',
        //   //   },
        //   // },
        //   xAxis: {
        //     allowDecimals: false,
        //     categories: months,
        //     tickmarkPlacement: "on",
        //     title: {
        //       enabled: false,
        //     },
        //     // labels: {
        //     //   formatter: function() {
        //     //     return this.value; // clean, unformatted number for year
        //     //   }
        //     // },
        //   },
        //   yAxis: {
        //     title: {
        //       text: "Sales",
        //     },
        //     labels: {
        //       formatter: function () {
        //         return this.value / 1000 + "k";
        //       },
        //     },
        //   },
        //   plotOptions: {
        //     area: {
        //       stacking: "normal",
        //       lineColor: "#666666",
        //       lineWidth: 1,
        //       marker: {
        //         lineWidth: 1,
        //         lineColor: "#666666",
        //       },
        //     },
        //   },
        //   series: [
        //     {
        //       color: '#E47200',
        //       name: "Last Year",
        //       data: lastMonth,
        //     },
        //     {
        //       color: '#FDF98B',
        //       name: "Current Year",
        //       data: currentMonth,
        //     },
        //     // {
        //     //   color: '#E30074',
        //     //   name: "Difference",
        //     //   data: diffArray,
        //     // },
        //   ],
        // });
      }
      // if (response.data[0]) {
      //   // console.log('business', response.data[0])
      //   var newData = [];
      //   response.data.forEach(row => {
      //     newData.push([row.date, row.total_sales | 0]);
      //   });
      //   // newData.sort(function(a, b) {
      //   //   return b[1] - a[1];
      //   // });
      //   this.loading = false;
    },
  },
};
</script>

<style scoped></style>
